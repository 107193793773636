import {useState, useCallback, useEffect} from 'react'
import {matchHash, parseDiffHash} from '../helpers/document-hash-helpers'
import {ssrSafeLocation} from '@github-ui/ssr-utils'

export function useSelectedDiffHash(): string {
  const [selectedPathDigest, setSelectedPathDigest] = useState<string>('')

  const onHashChange = useCallback(() => {
    const windowHash = parseDiffHash(ssrSafeLocation.hash ?? '') ?? ''
    const hashWithoutPrefix = windowHash.replace('diff-', '')
    setSelectedPathDigest(hashWithoutPrefix)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange() // Initialize on mount
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [onHashChange])

  return selectedPathDigest
}

export function useSelectedFullDiffHash(): string {
  const [selectedPathDigest, setSelectedPathDigest] = useState<string>('')

  const onHashChange = useCallback(() => {
    const windowHash = matchHash(ssrSafeLocation.hash ?? '') ?? ''
    let hashToUse = ''
    if (windowHash === '') {
      hashToUse = parseDiffHash(ssrSafeLocation.hash ?? '') ?? ''
    } else {
      hashToUse = windowHash?.[0] ?? ''
    }
    const hashWithoutPrefix = hashToUse.replace('diff-', '')
    setSelectedPathDigest(hashWithoutPrefix)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange() // Initialize on mount
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [onHashChange])

  return selectedPathDigest
}
